import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore } from '@store/store'
import { currencyToCurrency, formatNumber } from '@utils/common'
import { ItemPriceProps } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemPrice/types/types'
import { StyledItemFullPrice } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemFullPrice/styles/itemFullPrice.styled'

import { commonStyle } from '../../../../../../../../../../../styles'
import { WEIGHT_FOR_SALE } from '@utils/consts'

export function ItemFullPrice(props: ItemPriceProps) {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const currentProductNumber = useSelector((state: IStore) => state.cart.items[props.itemId]?.number)
  const currentPrice = useSelector((state: IStore) => state.cart.items[props.itemId]?.price)
  const product = useSelector((state: IStore) => state.cart.items[props.itemId])

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const fullPrice = (): number => {
    if (!currentProductNumber || !product) {
      return 0
    }

    const currentUnit = product.item?.units.find(unitItem => unitItem.type === 'BOX')

    // Если Китай
    if (product.region === 'Гуанчжоу') {
      if (product.unit === 'BOX') {
        // Если заказ коробка то отобразить нужно цену priceForUnit (т.к. она уже содержит в себе скидку)
        return currentProductNumber * (currentUnit?.priceForUnit || 0)
      } else if (product.item && product.region === 'Гуанчжоу' && currentProductNumber * product.item?.valueGram >= WEIGHT_FOR_SALE) {
        // Если заказ больше 4950 грамм то нужно показать скидку для товара с учётом заказа 4950 грамм
        return currentProductNumber * (product.item?.priceFiveKgCny || 0)
      } else if(currentPrice) {
        return currentProductNumber * currentPrice
      } else {
        return 0
      }
    }

    if (product.region === 'СПБ') {
      if (product.item?.spbDiscountPrice) {
        return currentProductNumber * (product.item?.spbDiscountPrice || 0)
      } else if(currentPrice) {
        return currentProductNumber * currentPrice
      } else {
        return 0
      }
    }

    return 0
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledItemFullPrice>
        { formatNumber(+fullPrice().toFixed(1)) }
        { currencyToCurrency(props.product.currency) }
      </StyledItemFullPrice>
    </ThemeProvider>
  )
}
